import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  // Security
  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const validateToken = () => {
    return api.get(`/api/users/validateToken`, {});
  };

  // User
  const getUsers = () => {
    return api.get(`/api/users`);
  };

  const removeUser = (id) => {
    return api.delete(`/api/users/${id}`);
  };

  const saveUser = (user) => {
    return api.post(`/api/users/${user.id}`, user);
  };

  const createUser = (user) => {
    return api.post(`/api/users`, user);
  };

  const saveVisibility = (visibility) => {
    return api.post(`/api/users/visibility`, visibility);
  };

  const setPassword = (id, password) => {
    return api.post(`/api/users/${id}/setPassword`, { password });
  };

  // Employe
  const getEmploye = (id) => {
    return api.get(`/api/employees/${id}`);
  };

  const getEmployees = () => {
    return api.get(`/api/employees`);
  };

  const removeEmploye = (id) => {
    return api.delete(`/api/employees/${id}`);
  };

  const saveEmploye = (employe) => {
    return api.post(`/api/employees/${employe.id}`, employe);
  };

  const createEmploye = (employe) => {
    return api.post(`/api/employees`, employe);
  };

  const getTeams = () => {
    return api.get(`/api/employees/teams`);
  };

  const getDivisions = () => {
    return api.get(`/api/employees/divisions`);
  };

  const getDepartments = () => {
    return api.get(`/api/employees/departments`);
  };

  const getLocations = () => {
    return api.get(`/api/employees/locations`);
  };

  const getPositions = () => {
    return api.get(`/api/employees/positions`);
  };

  const getTwOffices = () => {
    return api.get(`/api/employees/twOffices`);
  };

  const getPayrollSources = () => {
    return api.get(`/api/employees/payrollSources`);
  };

  const syncBos = () => {
    return api.get(`/api/employees/syncBos`);
  };

  // Budget
  const getTeamsByYear = (year) => {
    return api.get(`/api/budgets/teams/year/${year}`);
  };

  const getBudgetData = (filter, pagination) => {
    return api.post(`/api/budgets/getBudget`, { filter, pagination });
  };

  const getBudgetGroupBy = (filter, pagination) => {
    return api.post(`/api/budgets/getBudgetGroupBy`, { filter, pagination });
  };

  const editBudgetData = (value, employeeId, teamName, attr, month, filters, pagination) => {
    return api.post(`/api/budgets/updateData`, {
      value,
      employeeId,
      teamName,
      attr,
      month,
      filters,
      pagination,
    });
  };

  const editBudgetDataMonth = (employees, teamName, month, filters, pagination) => {
    return api.post(`/api/budgets/updateDataMonth`, {
      employees,
      teamName,
      month,
      filters,
      pagination,
    });
  };

  const importBudget = (data, month, year, currency) => {
    return api.post(`/api/budgets/import`, { data, month, year, currency });
  };

  const getImports = () => {
    return api.get(`/api/budgets/imports`);
  };

  // return functions for interface
  return {
    setToken,
    login,

    // User
    validateToken,
    getUsers,
    removeUser,
    saveUser,
    createUser,
    saveVisibility,
    setPassword,

    // Employe
    getEmploye,
    getEmployees,
    removeEmploye,
    saveEmploye,
    createEmploye,
    getTeams,
    getDivisions,
    getDepartments,
    getLocations,
    getPositions,
    getTwOffices,
    getPayrollSources,
    syncBos,

    // Budget
    getTeamsByYear,
    getBudgetData,
    getBudgetGroupBy,
    editBudgetData,
    editBudgetDataMonth,
    importBudget,
    getImports,
  };
};

// Creates a singleton
export default createApi();
